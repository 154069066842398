import { styled } from '@portfolio/styles'
import { Close } from '@portfolio/icons'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Socials as SocialsType } from '@portfolio/models'
import { CopyButton, Modal, Socials } from '../../index'

declare global {
  interface Window {
    hbspt: any
  }
}

const ModalWrap = styled('div', {
  display: 'flex',
  position: 'relative',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '$40 0',
  maxWidth: '640px',

  '@lg': {
    width: '640px',
  },
})
const ContentWrap = styled('div', {})
const FormWrap = styled('div', {})
const CloseButton = styled('button', {
  position: 'absolute',
  top: '$40',
  right: '$40',
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$rMax',
  transition: '0.2s background',

  '&:hover': {
    background: '$gray500_02',
  },

  '&:active': {
    background: '$gray500_01',
  },

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})
const ModalTitle = styled('h1', {
  projectFont: 'display02',
  marginBottom: '80px',
  padding: '0 $32 0 $40',
  '@sm': {
    padding: '0 $48 0 $40',
  },
  '@md': {
    padding: '0 80px 0 $40',
  },
})

const ContactWrap = styled('div', {
  '@md': {
    margin: '0 auto',
  },
})

const Contact = styled('div', {
  padding: '$28 $40 $32',
  borderTop: '1px solid $gray500_01',
})

const ContactTitle = styled('h2', {
  projectFont: 'heading08',
  marginBottom: '$8',
})

const ContactMail = styled('div', {
  projectFont: 'body03',
  display: 'flex',
  justifyContent: 'flex-start',
  columnGap: '$12',
})

const SocialsWrap = styled('div', {
  padding: '$28 $40 $32',
})

const SocialsTitle = styled('h2', {
  projectFont: 'heading08',
  marginBottom: '$16',
})

export type ContactFormModalProps = {
  socials: SocialsType
  contactForm?: {
    formTitle?: string
    contactEmail?: {
      email?: string
    }
    emailTitle?: string
  }
  formId: string
  formPortalId: string
  renderTrigger?: (action: { click: () => void }) => ReactElement
}

export const ContactFormModal = ({
  socials,
  contactForm,
  formId,
  formPortalId,
  renderTrigger,
}: ContactFormModalProps) => {
  const { t } = useTranslation('contactPage')
  const [isContactFormOpen, setIsContactFormOpen] = useState(false)
  const [title, setTitle] = useState(contactForm?.formTitle)

  const handleModalClose = () => {
    setIsContactFormOpen(false)
    setTimeout(() => {
      setTitle(contactForm?.formTitle)
    }, 500)
  }

  const actionProps = {
    click: () => setIsContactFormOpen(true),
  }

  useEffect(() => {
    if (isContactFormOpen) {
      window.hbspt.forms.create({
        region: 'eu1',
        portalId: formPortalId,
        formId: formId,
        target: '.modal-contact-form',
      })
    }
  }, [isContactFormOpen, formPortalId, formId])

  if (!renderTrigger) return null

  return (
    <>
      {renderTrigger(actionProps)}
      <Modal
        preventScroll={true}
        isOpen={isContactFormOpen}
        onRequestClose={handleModalClose}
        size={'stretch-y'}
        position={'right'}
        transition={'slide-from-right'}
      >
        <ModalWrap>
          <CloseButton onClick={handleModalClose}>
            <Close />
          </CloseButton>
          <ContentWrap>
            <ModalTitle>{title}</ModalTitle>
            <FormWrap>
              <div className="modal-contact-form"></div>
            </FormWrap>
            <ContactWrap>
              <Contact>
                <ContactTitle>{contactForm?.emailTitle}</ContactTitle>
                <ContactMail>
                  {contactForm?.contactEmail?.email}
                  <CopyButton
                    copyText={contactForm?.contactEmail?.email ?? ''}
                    theme="dark"
                    context={`ContactPage - Prefer email contact?`}
                  />
                </ContactMail>
              </Contact>
            </ContactWrap>
          </ContentWrap>
          {socials && socials && (
            <SocialsWrap>
              <SocialsTitle>{t('followUs')}</SocialsTitle>
              <Socials
                items={socials.items}
                iconsColor="dark"
                priority={false}
              />
            </SocialsWrap>
          )}
        </ModalWrap>
      </Modal>
    </>
  )
}
