import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createRichTextProjection } from '../projections/createRichTextProjection'

export const createSectionNewsletterV2Projection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        anchorId,
        ${coalesceLocaleField('newsletterSuccessMessage', localeId)},
        hubspotFormPortalId,
        hubspotFormId,
        ${coalesceLocaleField(
          'privacyPolicyInfo',
          localeId,
        )}[]${RICH_TEXT_PROJECTION},
      }
  `
}
