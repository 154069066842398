import { DEFAULT_LOCALE_ID, LocaleId } from '@portfolio/configs'
import { groq } from 'next-sanity'
import { createBaseImageProjection } from './createBaseImageProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createLinkProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)

  const socialsQuery = groq`
    *[_type == "socials"]{
      items[]{
        url,
        logoLight${BASE_IMAGE_PROJECTION},
        logoDark${BASE_IMAGE_PROJECTION},
      }
    }[0]
  `

  const contactFormQuery = groq`
    *[_type == "contactForm"] {
      ${coalesceLocaleField('formTitle', localeId)},
      ${coalesceLocaleField('emailTitle', localeId)},
      contactEmail -> {${coalesceLocaleField('email', localeId)}},
    }[0]`

  return groq`{
    linkType,
    linkType == 'internal' && defined(internal) => {
      "href": select(
        defined(query) => internal->{path}.path.current + query,
        internal->{path}.path.current,
      )
    },
    linkType == 'external' => {
      "href": coalesce(external.${localeId}, external.${defaultLocaleId}),
      "target": "_blank"
    },
    linkType == 'phone' => {
      "href": "tel:" + coalesce(phone.${localeId}, phone.${defaultLocaleId})
    },
    linkType == 'email' => {
      "href": "mailto:" + coalesce(email.${localeId}, email.${defaultLocaleId})
    },
    linkType == 'file' => {
      "href": file.asset->url + "?dl=",
      "download": true,
    },
    linkType == 'linkAction' => {
      "action": linkAction.linkActionPicker[] {
        "type": _type,
        "payload": {
          _type == 'klaviyoModalForm' => {
            "desktopFormId": desktopKlaviyoFormId,
            "mobileFormId": mobileKlaviyoFormId,
            "label": coalesce(label.${localeId}, label.${defaultLocaleId})
          },
          _type == 'contactModalForm' => {
            "contactFormModalTitle": coalesce(label.${localeId}, label.${defaultLocaleId}),
            "socials":${socialsQuery},
            "contactForm": ${contactFormQuery},
            "formId": formId,
            "formPortalId": formPortalId,
          },
          _type == 'hubspotForm' => {
            "formId": formId,
            "formPortalId": formPortalId,
          }
        }
      }[0]
    },
  }`
}
