import { ReactElement, useEffect, useState } from 'react'
import { Modal } from '../Modal/Modal'
import { styled } from '@portfolio/styles'
import { Close } from '@portfolio/icons'

const ModalWrap = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  boxSizing: 'border-box',
  minWidth: '200px',
  maxWidth: '600px',
  borderRadius: '$16',
  borderStyle: 'none',
  borderWidth: '0px',
  backgroundColor: '$black',
  padding: '0',
  flex: '1 1 0%',
  position: 'relative',
  color: '$white',

  '@sm': {
    minWidth: '400px',
  },

  '@md': {
    minWidth: '600px',
  },
})

const CloseButton = styled('button', {
  position: 'absolute',
  top: '$16',
  right: '$16',
  width: 22,
  height: 22,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$rMax',
  backgroundColor: '$black',
  padding: '$4',
})

const FormWrap = styled('div', { width: '100%' })

export type HubspotFormProps = {
  formId: string
  formPortalId: string
  renderTrigger: (action: { click: () => void }) => ReactElement
}

export const HubspotForm = ({
  formId,
  formPortalId,
  renderTrigger,
}: HubspotFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (isModalOpen) {
      window.hbspt.forms.create({
        region: 'eu1',
        portalId: formPortalId,
        formId: formId,
        target: '.hs-form-frame',
      })
    }
  }, [isModalOpen, formPortalId, formId])

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const actionProps = {
    click: () => setIsModalOpen(true),
  }

  return (
    <>
      {renderTrigger(actionProps)}
      <Modal
        preventScroll={true}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        position={'center'}
        transition={'fade'}
      >
        <ModalWrap>
          <CloseButton onClick={handleModalClose}>
            <Close color="white" />
          </CloseButton>
          <FormWrap>
            <div className="hs-form-frame"></div>
          </FormWrap>
        </ModalWrap>
      </Modal>
    </>
  )
}
